import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import posed from "react-pose"
import Layout from "../components/layout"
import styles from "../styles/contact.module.css"
import closeSVG from "../images/close.svg"

const Box = posed.div({
  closed: { y: "300px" },
  open: { y: "0px", transition: { duration: 600 } },
})

const Back = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.2,
  },
  press: {
    scale: 1.1,
  },
})

export default () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gaston Hummel</title>
      </Helmet>
      <Box className={styles.container} initialPose="closed" pose="open">
        <div className="headerOther">
          <Back>
            <Link to="/">
              <img src={closeSVG} alt="back" height="32" width="32" />
            </Link>
          </Back>
          <h2 className={styles.center}>Contact</h2>
        </div>
        <div className={styles.center}>
          <p>
            Email:&nbsp;
            <a href="mailto:gastonhummel@hotmail.com" target="_new">
              gastonhummel@hotmail.com
            </a>
          </p>
          <p>
            Twitter:&nbsp;
            <a href="https://twitter.com/GastonHummel" target="_new">
              https://twitter.com/GastonHummel
            </a>
          </p>
          <p>
            LinkedIn:&nbsp;
            <a href="https://www.linkedin.com/in/gastonhummel" target="_new">
              https://www.linkedin.com/in/gastonhummel
            </a>
          </p>
          <p>
            YouTube:&nbsp;
            <a href="https://www.youtube.com/c/GastonHummel" target="_new">
              https://www.youtube.com/c/GastonHummel
            </a>
          </p>
        </div>
      </Box>
    </Layout>
  )
}
